import React from "react";
import PropTypes from "prop-types";
import Head from "next/head";
import Report from "components/Report";
import { useRouter } from "next/router";

// This is useful when the provided url already has query parameters, to avoid
// urls of the form http://example.com?first=1?second=2
// returns a new url string with the provided query object merged with the
// current parameters
const addParametersToUrl = (url, query = {}) => {
  const urlObject = new URL(url);
  for (let [key, value] of Object.entries(query)) {
    urlObject.searchParams.append(key, value);
  }
  return urlObject.toString();
};

function SEO(props) {
  const {
    author,
    description,
    image,
    noIndex,
    ogType = "website",
    title: titleProp,
    url,
  } = props;
  const { query } = useRouter();
  const { t } = query || {};
  const title = `${titleProp} | PropertySimple`;

  let ogTagsURL = url;
  if (url && t) {
    ogTagsURL = addParametersToUrl(url, { t });
  }

  return (
    <>
      <Report
        level="debug"
        message="Bot request for SEO component"
        variables={{ ...props, title }}
      />
      <Head>
        <title>{title}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />

        {author && <meta name="author" content={author} />}

        <meta property="og:description" content={description} />
        <meta property="og:image:height" content="768" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:site_name" content="PropertySimple" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content={ogType} />
        <meta property="og:url" content={ogTagsURL} />
        {image && (
          <>
            <meta property="og:image" content={image} />
            <meta property="og:image:url" content={image} />
          </>
        )}
        {noIndex && <meta name="robots" content="noindex" />}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:site" content="@psimpleusa" />
        <meta name="twitter:title" content={title} />
      </Head>
    </>
  );
}

SEO.propTypes = {
  author: PropTypes.string,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SEO;
