import React from "react";
import styled from "styled-components";
import { colors } from "@propertysimple/styles";

const Title = styled.h2`
  color: ${colors.BG};
  font-size: 2.5rem;
  font-weight: normal;
  margin: 0;
  margin-bottom: 2rem;
`;

const Subtitle = styled.h3`
  color: ${colors.BG};
  font-size: 2rem;
  font-weight: normal;
  margin: 0;
  margin-bottom: 2rem;
`;

const Link = styled.a`
  text-decoration: none;
  background: rgba(255, 255, 255, 0.3);
  border-color: rgba(255, 255, 255, 0);
  color: #fff;
  padding: 0.6875rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;

  &:hover {
    border-color: rgba(255, 255, 255, 0.1);
  }
`;

const Wrapper = styled.div`
  background: linear-gradient(135deg, #6f42c1 0%, #4582ec 100%);
  color: white;
  box-sizing: border-box;
  padding: 8rem 1rem;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;

  & > * {
    max-width: 960px;
    box-sizing: border-box;
  }
`;

function BookADemoCTA({
  title = "Don't hold back on social media any longer!",
  subtitle,
  buttonText = "Book a Demo",
  onClick,
  href = "https://learn.propertysimple.com/marketing-for-realtors",
  style,
  className,
}) {
  return (
    <Wrapper {...{ style, className }}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      {onClick ? (
        <Link as="button" onClick={onClick}>
          {buttonText}
        </Link>
      ) : (
        <Link href={href} target="_blank" rel="noreferrer">
          {buttonText}
        </Link>
      )}
    </Wrapper>
  );
}

export default BookADemoCTA;
