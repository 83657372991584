import {
  PRIMARY_PARAM_NAMES,
  SECONDARY_PARAM_NAMES,
  DEFAULT_PARAMS,
  DEFAULT_MAP_ZOOM_LEVEL,
} from "./constants";
import { slugify } from "@propertysimple/components";

export function getPrimaryParamsCount(params) {
  if (!params) return 0;
  let count = 0;
  PRIMARY_PARAM_NAMES.forEach((param) => {
    if (typeof param === "string") {
      if (params[param] !== DEFAULT_PARAMS[param]) {
        count += 1;
      }
    }
  });
  return count;
}

export function getSecondaryParamsCount(params) {
  if (!params) return 0;
  let count = 0;
  SECONDARY_PARAM_NAMES.forEach((param) => {
    if (typeof param === "string") {
      if (params[param] !== DEFAULT_PARAMS[param]) {
        count += 1;
      }
    }
  });
  return count;
}

export function getUsedParamsCount(params) {
  const prim = getPrimaryParamsCount(params);
  const sec = getSecondaryParamsCount(params);
  return prim + sec;
}

function parseCommaSepList(list) {
  if (list) {
    list = list.trim();
    if (list) {
      return list.split(",");
    }
  }
  return [];
}

/**
 * Extracts parameters given as a comma separated list of floating
 * point numbers. Usable for example for the map bbox and center.
 *
 * Returns:
 * - `null` if any of the parameters is not a number
 * - `null` if number of parameters is unexpected
 * - an array of floating point parameters otherwise.
 */
export const getFloatArray = (searchParams, paramName, expectedLength) => {
  let params = searchParams[paramName];
  if (!params) {
    return null;
  }
  params = params.split(",");
  params = params.map((param) => parseFloat(param));
  if (params.length !== expectedLength) {
    return null;
  }
  params = params.filter((param) => Number.isFinite(param));
  if (params.length === expectedLength) {
    return params;
  }
  return null;
};

function buildArrayParam(params, name) {
  if (params[name]) {
    return params[name].map((val) => `${val}`).join(",");
  }
  return null;
}

const getIntParam = (params, paramName, defaultValue) => {
  let val = parseInt(params[paramName]);
  if (Number.isFinite(val)) {
    return val;
  }
  return defaultValue;
};

const getFloatParam = (params, paramName, defaultValue) => {
  let val = parseFloat(params[paramName]);
  if (Number.isFinite(val)) {
    return val;
  }
  return defaultValue;
};

const getBoolParam = (params, paramName, defaultValue = false) => {
  let rawValue = params[paramName];
  return rawValue !== null ? rawValue === "true" : defaultValue;
};

const getStringParam = (params, paramName, defaultValue = "") => {
  let value = params[paramName];
  return value || defaultValue;
};

function parseHasParam(params) {
  const has = parseCommaSepList(params["has"]);
  //defaults are "false" automatically
  return {
    hasDisabledAccess: has.includes("disabled_access"),
    hasElevator: has.includes("elevator"),
    hasFireplace: has.includes("fireplace"),
    hasGatedEntry: has.includes("gated_entry"),
    hasHotTubSpa: has.includes("hot_tub_spa"),
    hasPool: has.includes("pool"),
    hasSecuritySystem: has.includes("security_system"),
    hasVaultedCeiling: has.includes("vaulted_ceiling"),
  };
}

function buildHasParam(params) {
  const has = [];
  params.hasDisabledAccess && has.push("disabled_access");
  params.hasElevator && has.push("elevator");
  params.hasFireplace && has.push("fireplace");
  params.hasGatedEntry && has.push("gated_entry");
  params.hasHotTubSpa && has.push("hot_tub_spa");
  params.hasPool && has.push("pool");
  params.hasSecuritySystem && has.push("security_system");
  params.hasVaultedCeiling && has.push("vaulted_ceiling");
  return has.join(",");
}

function parsePropertyTypesParams(params) {
  const types = parseCommaSepList(params["property_types"]);
  //defaults are "false" automatically
  return {
    propertyTypesApartments: types.includes("apartments"),
    propertyTypesHouses: types.includes("houses"),
    propertyTypesLotsLand: types.includes("lotsLand"),
    propertyTypesManufacturedMobile: types.includes("manufacturedMobile"),
    propertyTypesOther: types.includes("other"),
    propertyTypesTownhomes: types.includes("townhomes"),
  };
}

function buildPropertyTypesParam(params) {
  const types = [];
  params.propertyTypesApartments && types.push("apartments");
  params.propertyTypesHouses && types.push("houses");
  params.propertyTypesLotsLand && types.push("lotsLand");
  params.propertyTypesManufacturedMobile && types.push("manufacturedMobile");
  params.propertyTypesOther && types.push("other");
  params.propertyTypesTownhomes && types.push("townhomes");
  return types.join(",");
}

export function cleanQueryParams(params, rollbar) {
  const defaults = DEFAULT_PARAMS;
  try {
    return Object.assign(
      {},
      {
        //primary params
        lowPrice: getIntParam(params, "low_price", defaults.lowPrice),
        highPrice: getIntParam(params, "high_price", defaults.highPrice),
        bathrooms: getIntParam(params, "bathrooms", defaults.bathrooms),
        bedrooms: getIntParam(params, "bedrooms", defaults.bedrooms),
        //secondary params part 1
        minLotSize: getFloatParam(params, "min_lot_size", defaults.minLotSize),
        maxLotSize: getFloatParam(params, "max_lot_size", defaults.maxLotSize),
        minLivingArea: getFloatParam(
          params,
          "min_living_area",
          defaults.minLivingArea,
        ),
        maxLivingArea: getFloatParam(
          params,
          "max_lining_area",
          defaults.maxLivingArea,
        ),
      },
      parseHasParam(params),
      parsePropertyTypesParams(params),
      //meta params
      {
        bbox: getFloatArray(params, "bbox", 4) || defaults.bbox,
        center: getFloatArray(params, "center", 2) || defaults.center,
        from: getIntParam(params, "from", defaults.from),
        hideAddPropertiesHelp: getBoolParam(
          params,
          "hide_add_properties_help",
          defaults.hideAddPropertiesHelp,
        ),
        listingCategory: getStringParam(
          params,
          "category",
          defaults.listingCategory,
        ),
        placeType: getStringParam(params, "place_type", defaults.placeType),
        queryText: params["query_text"] || defaults.queryText,
        shapeId: params["shape_id"] || defaults.shapeId,
        size: getIntParam(params, "size", defaults.size),
        sort: getIntParam(params, "sort", defaults.sort),
        source: params["source"] || defaults.source,
        zipcode: getStringParam(params, "zipcode") || defaults.zipcode,
        zoom: getIntParam(params, "zoom", defaults.zoom),
      },
    );
  } catch (error) {
    rollbar.error("Error in cleanQueryParams", { error, params });
    return defaults;
  }
}

function setVal(params, fromName, toName, searchParams) {
  const defaults = DEFAULT_PARAMS;
  if (params[fromName] !== defaults[fromName]) {
    searchParams.set(toName, `${params[fromName]}`);
  }
}

/**
 * Uses the params object in the format
 * returned by the function cleanQueryParams
 * and returns the URLSearchParams() object
 */
export function getUrlParams(params) {
  const sp = new URLSearchParams();
  if (!params) return sp;
  const has = buildHasParam(params);
  has && sp.set("has", has);
  const types = buildPropertyTypesParam(params);
  types && sp.set("property_types", types);
  setVal(params, "lowPrice", "low_price", sp);
  setVal(params, "highPrice", "high_price", sp);
  setVal(params, "bathrooms", "bathrooms", sp);
  setVal(params, "bedrooms", "bedrooms", sp);
  setVal(params, "minLotSize", "min_lot_size", sp);
  setVal(params, "maxLotSize", "max_lot_size", sp);
  setVal(params, "minLivingArea", "min_living_area", sp);
  setVal(params, "maxLivingArea", "max_lining_area", sp);
  const bbox = buildArrayParam(params, "bbox");
  bbox && sp.set("bbox", bbox);
  const center = buildArrayParam(params, "center");
  center && sp.set("center", center);
  setVal(params, "from", "from", sp);
  setVal(params, "hideAddPropertiesHelp", "hide_add_properties_help", sp);
  setVal(params, "listingCategory", "category", sp);
  setVal(params, "placeType", "place_type", sp);
  setVal(params, "queryText", "query_text", sp);
  setVal(params, "shapeId", "shape_id", sp);
  setVal(params, "size", "size", sp);
  setVal(params, "sort", "sort", sp);
  setVal(params, "source", "source", sp);
  setVal(params, "zipcode", "zipcode", sp);
  setVal(params, "zoom", "zoom", sp);
  return sp;
}

/*
 * Returns the corresponding search page url.
 */
export function getSearchPageUrl(params) {
  const searchString = getUrlParams(params).toString();
  return searchString ? `/search?${searchString}` : "/search";
}

function getMapboxContextParam(place, paramName) {
  let param = place.context.find((ctx) => ctx.id.indexOf(paramName) !== -1);
  if (param) {
    return param.text;
  }
  return null;
}

export const getZoomLevelForMapboxPlace = (place) => {
  if (!place || !place.placeType) {
    return DEFAULT_MAP_ZOOM_LEVEL;
  }
  let type = place.placeType[0];
  if (type === "region") {
    return 6;
  } else if (type === "address") {
    return 13;
  } else if (type === "postcode") {
    return 11;
  } else if (type === "poi") {
    return 12;
  }
  return DEFAULT_MAP_ZOOM_LEVEL;
};

export function getMapboxPlaceUrl(place, params) {
  let sp = getUrlParams(params);
  sp.delete("shape_id");
  sp.set("source", "mapbox");
  sp.set("query_text", place.placeName);
  sp.set("place_type", place.placeType);

  // pagination params
  sp.set("from", 0);
  sp.set("size", 50);

  // set either a bounding box or a center + zoom level
  if (place.bbox) {
    sp.set("bbox", place.bbox.join(","));
    sp.delete("center");
    sp.delete("zoom");
  } else {
    sp.set("center", place.center.join(","));
    sp.set("zoom", getZoomLevelForMapboxPlace(place));
    sp.delete("bbox");
  }

  let state = getMapboxContextParam(place, "region");
  if (state) {
    sp.set("state", state);
  }
  let neighborhood = getMapboxContextParam(place, "neighborhood");
  if (neighborhood) {
    sp.set("neighborhood", neighborhood);
  }
  let zipcode = (getMapboxContextParam(place, "postcode") || "").trim();
  if (zipcode) {
    sp.set("zipcode", zipcode);
  }
  return `/search?${sp.toString()}`;
}

export function getCrmShapeUrl(place, params) {
  const sp = getUrlParams(params);
  sp.delete("zoom");
  sp.delete("center");
  sp.set("bbox", place.bbox.join(","));
  sp.set("shape_id", place.shapeId);
  sp.set("source", "crm");
  sp.set("place_type", place.placeType);
  let placeName = place.placeName;
  if (place.placeType === "zipcode") {
    const zipcode = placeName.trim();
    sp.set("zipcode", zipcode);
    placeName = zipcode;
  }
  sp.set("query_text", placeName);
  return `/search?${sp.toString()}`;
}

export function getListingUrl(listing) {
  const category =
    listing.listing_category === "Purchase" ? "for-sale" : "for-rent";
  const addr = slugify(listing.address_full_street_address);
  const state = slugify(listing.address_state_or_province);
  const city = slugify(listing.address_city);
  return `/search/${state}/${city}/${category}/${addr}/${listing.id}`;
}

export function getPlaceUrl(place, params) {
  if (place.placeSource === "crm") {
    if (place.placeType === "listing") {
      return getListingUrl(place);
    } else if (place.shapeId) {
      return getCrmShapeUrl(place, params);
    }
  } else if (place.placeSource === "mapbox") {
    return getMapboxPlaceUrl(place, params);
  }
  return null;
}
