import React, { useState } from "react";
import styled from "styled-components";
import { Modal, FixedSizeVideo } from "@propertysimple/components";
import { breakpointInPX } from "../constants";
import Image from "next/legacy/image";
import joshThumbnail from "./josh-thumbnail.jpg";
import useWindowSize from "lib/hooks/useWindowSize";

const Wrapper = styled.div`
  overflow: hidden;
  max-width: 20rem;

  @media (max-width: ${breakpointInPX}) {
    max-width: 250px;
    max-height: 250px;
  }
`;

export default function CTAVideo() {
  const [showModal, setShowModal] = useState(false);
  const { width = 1, height = 1 } = useWindowSize();
  let videoHeight = Math.floor(height * 0.9);
  let videoWidth = Math.floor(videoHeight * (17 / 30)); // Video looks good with 17rem width and 30rem height, so I keep that ratio.
  if (width < 500) {
    videoWidth = "17rem";
    videoHeight = "30rem";
  }

  return (
    <Wrapper>
      <Image
        src={joshThumbnail}
        style={{ borderRadius: "1rem" }}
        className="cursor-pointer w-full"
        alt=""
        onClick={() => setShowModal("true")}
      />

      {showModal && (
        <Modal
          closeModal={() => setShowModal(false)}
          style={{
            height: videoHeight,
            width: videoWidth,
            padding: "0",
            overflow: "hidden",
          }}
          withoutXButton
          fullScreen
        >
          <FixedSizeVideo
            src="https://player.vimeo.com/video/760006076?h=500689508e&title=0&byline=0&portrait=0&autoplay=1"
            title=""
            height={videoHeight}
            width={videoWidth}
          />
        </Modal>
      )}
    </Wrapper>
  );
}
