import React from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import styled from "styled-components";
import { useRollbar } from "@rollbar/react";
import { colors, constants, namedColors } from "@propertysimple/styles";
import { getPlaceUrl } from "components/Search/utilities";

const PropertySearchBox = dynamic(() => import("./PropertySearchBox"), {
  ssr: false,
});

const Wrapper = styled.div`
  width: 100%;
  max-width: 40rem;
  max-width: 700px;
  text-align: center;
  position: relative;

  .property-search-box {
    display: flex;
    align-items: center;
    height: 60px;
    border-radius: ${constants.BORDER_RADIUS};
    background: white;
    padding: 0 0.625rem 0 0.75rem;
    position: relative;
  }

  .property-search-box-suggestions {
    overflow: hidden;
    border-bottom-left-radius: ${constants.BORDER_RADIUS};
    border-bottom-right-radius: ${constants.BORDER_RADIUS};
  }

  svg.fa-search {
    color: ${colors.HOT};
    top: 0.75rem;
  }

  input {
    padding-top: 4px;
  }

  .property-search-box-suggestions {
    position: absolute;
    width: 100%;
    top: 54px;
    left: 0;
  }

  .property-search-box-clear-input {
    width: 60px;
    height: 60px;
  }

  input::placeholder {
    color: ${namedColors.LIGHT_GREY};
  }

  @media (min-width: 768px) {
    width: 80%;
  }
`;

export default function Input({
  placeholder = "Search for an address, city, ZIP, or neighborhood...",
  className,
}) {
  const rollbar = useRollbar();
  const router = useRouter();

  function handleSuggestionSelected(place) {
    const url = getPlaceUrl(place);
    if (url) {
      console.log("url", url);
      router.push(url);
    }
    rollbar.error("Could not navigate to the search result", { place });
  }

  return (
    <Wrapper className={className}>
      <PropertySearchBox
        placeholder={placeholder}
        appEnvironment={process.env.APP_ENVIRONMENT}
        theme="homepage"
        onSuggestionSelected={handleSuggestionSelected}
      />
    </Wrapper>
  );
}
