import { useState, useEffect, useRef } from "react";

export default function useComponentVisible(initialIsVisible, onClose) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      //event.stopPropagation() !! IMPORTANT - don't stopPropagation on the document events
      //because all other event handlers won't work
      setIsComponentVisible(false);
      onClose && onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}
