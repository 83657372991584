import React from "react";
import CTAVideo from "./CTAVideo";
import styled from "styled-components";
import Content from "./Content";
import Navbar from "./Navbar";
import { breakpointInPX } from "../constants";

const Wrapper = styled.div`
  color: white;
  display: grid;
  background: url("/images/homepage/desktop-banner.jpg");
  background-position: center bottom;
  background-size: cover;
  padding-bottom: 4rem;

  @media (max-width: ${breakpointInPX}px) {
    background: url("/images/homepage/mobile-banner.jpg");
    padding-bottom: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  align-items: center;
  max-width: 1000px;
  padding: 1rem 2rem;
  justify-items: center;
  margin: auto;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: ${breakpointInPX}px) {
    justify-items: baseline;
    grid-template-columns: 1fr;
  }
`;

export default function Header() {
  return (
    <Wrapper>
      <Navbar />
      <Grid>
        <Content />
        <CTAVideo />
      </Grid>
    </Wrapper>
  );
}
