import React from "react";

function HorizontalLogo({ className, color = "white", height = "1rem" }) {
  return (
    <svg
      style={{ height }}
      className={className}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 548.69 182.71"
    >
      <title>PropertySimple Logo</title>
      <path
        fill={color}
        d="M563.49 121.47l-.14-.06a526.5 526.5 0 0 0-66-21.1c-11.23-2.76-22.52-5.34-33.87-7.58l-1.73-.32a2.3 2.3 0 0 0 .67-1.32l6-53.93a2.47 2.47 0 0 0-2.25-2.68l-24.3-2.72a2.38 2.38 0 0 0-.71 0l2.35-21c.21-1.87-.74-3.52-2.13-3.67l-24.29-2.7c-1.38-.15-2.69 1.23-2.89 3.11l-4.27 38.12-19.87-2.23c-1.27-.14-2.39.5-2.49 1.43l-4.14 36.91a871.88 871.88 0 0 0-92.69-2.93c-23.11.52-46.21 1.54-69.21 3.69Q204.28 84 187.09 86c-11.46 1.39-22.89 3-34.29 4.75-45.58 7-90.71 16.84-134.63 31.07a1.35 1.35 0 0 0 .69 2.6q33.71-6.75 67.65-11.57c22.62-3.29 45.26-6.26 68-8.48 11.35-1.15 22.72-2.08 34.09-3s22.73-1.8 34.11-2.47q34.14-2 68.3-2.15c22.78.07 45.53.17 68.26 1.26s45.4 2.93 68 5.39q33.93 3.45 67.52 9.61c11.19 2 22.38 4.1 33.5 6.54q8.34 1.81 16.65 3.83t16.61 4a3.15 3.15 0 0 0 2-6zM451.61 38.58c0-.27.48-.43 1-.38l9.08 1c.52.06.92.32.89.59l-1.21 10.74c0 .26-.47.43-1 .37l-9.08-1c-.53 0-.92-.31-.89-.58zM427 12.22c0-.27.48-.43 1-.37l9.09 1c.51.05.91.31.88.58l-1.2 10.74c0 .27-.47.43-1 .37l-9.09-1c-.52-.06-.92-.32-.89-.59zM425.07 31c0-.27.47-.43 1-.38l9.09 1c.52.06.91.32.89.58L434.83 43c0 .26-.47.43-1 .37l-9.09-1c-.52-.06-.91-.32-.89-.58z"
        transform="translate(-17.24 -4.38)"
      />
      <path
        fill={color}
        d="M25 165.68v20h-7.42v-49.3H26a37.74 37.74 0 0 1 9.36.87 12.48 12.48 0 0 1 5.64 3.26A14 14 0 0 1 45.24 151a13.53 13.53 0 0 1-4.53 10.74q-4.55 4-12.24 4zm0-6.9h2.8q10.26 0 10.25-7.89 0-7.63-10.57-7.64H25zM69 164.69l15.27 21h-9.1L61 165.52h-1.3v20.19h-7.45v-49.33H61q9.76 0 14.12 3.68a13.49 13.49 0 0 1 4.79 10.8 14 14 0 0 1-3 9 13.42 13.42 0 0 1-7.91 4.83zM59.7 159h2.36q10.57 0 10.58-8.08 0-7.57-10.29-7.57H59.7zM87.41 160.82a24.18 24.18 0 0 1 7.64-17.89 25.14 25.14 0 0 1 18.27-7.47A25.8 25.8 0 0 1 139 161.11a24.25 24.25 0 0 1-7.6 18 26.08 26.08 0 0 1-35.59.83q-8.4-7.27-8.4-19.12zm7.51.1a17.82 17.82 0 0 0 5.5 13.45 18.19 18.19 0 0 0 25.71-.1 19.14 19.14 0 0 0 .07-26.45 18.4 18.4 0 0 0-26 0 17.82 17.82 0 0 0-5.28 13.1zM155.12 165.68v20h-7.44v-49.3h8.43a37.63 37.63 0 0 1 9.36.87 12.52 12.52 0 0 1 5.63 3.26 14 14 0 0 1 4.25 10.49 13.54 13.54 0 0 1-4.54 10.74c-3 2.64-7.1 4-12.24 4zm0-6.9h2.78q10.26 0 10.26-7.89 0-7.63-10.58-7.64h-2.46zM209.58 143.38H189.8v11.85H209v7h-19.2v16.48h19.78v7h-27.22v-49.33h27.22zM235.41 164.69l15.27 21h-9.11l-14.09-20.19h-1.34v20.19h-7.44v-49.31h8.72q9.78 0 14.12 3.68a13.49 13.49 0 0 1 4.79 10.8 14 14 0 0 1-3 9 13.37 13.37 0 0 1-7.92 4.83zm-9.27-5.69h2.37q10.56 0 10.57-8.08 0-7.57-10.29-7.57h-2.65zM270.47 143.38v42.33H263v-42.33h-11.32v-7h30.1v7zM300.93 164.4l-16.1-28h8.56l11.28 19.72L316 136.38h8.56l-16.17 28v21.31h-7.44z"
        transform="translate(-17.24 -4.38)"
      />
      <path
        fill={color}
        d="M355.92 149a15.71 15.71 0 0 0-4.19-2.52 11.05 11.05 0 0 0-4.06-.82 6.51 6.51 0 0 0-4.05 1.18 3.66 3.66 0 0 0-1.58 3.07 3.09 3.09 0 0 0 .79 2.16 6.67 6.67 0 0 0 2.06 1.47 16.64 16.64 0 0 0 2.88 1.08c1.07.31 2.13.63 3.17 1q6.29 2.1 9.2 5.6a13.78 13.78 0 0 1 2.91 9.12 17.78 17.78 0 0 1-1.28 6.87 14.2 14.2 0 0 1-3.73 5.27 17 17 0 0 1-6 3.4 25 25 0 0 1-8.08 1.21 29.72 29.72 0 0 1-17.34-5.56l5.5-10.33a22.43 22.43 0 0 0 5.69 3.79 13.67 13.67 0 0 0 5.56 1.24 6.62 6.62 0 0 0 4.68-1.44 4.37 4.37 0 0 0 1.54-3.27 4.46 4.46 0 0 0-.39-1.93 4.22 4.22 0 0 0-1.31-1.5 10.34 10.34 0 0 0-2.39-1.28c-1-.39-2.17-.83-3.57-1.31-1.65-.52-3.28-1.1-4.87-1.73a15.17 15.17 0 0 1-4.25-2.52 11.63 11.63 0 0 1-3-4 13.89 13.89 0 0 1-1.15-6.05 17.61 17.61 0 0 1 1.21-6.64 14.66 14.66 0 0 1 3.41-5.1 15.14 15.14 0 0 1 5.36-3.31 20.41 20.41 0 0 1 7.1-1.18 30.88 30.88 0 0 1 7.65 1 33.89 33.89 0 0 1 7.66 3zM383.26 136.38v49.33h-12.82v-49.33zM390.72 185.71l8.38-49.33h12.69l9.88 26.3 9.81-26.3h12.69l8.38 49.33h-12.76l-4.25-28.4-11.65 28.4h-5.1l-11.06-28.4-4.25 28.4zM472.83 185.71H460v-49.33h20.41q8.31 0 12.72 4.32t4.42 12.17q0 7.84-4.42 12.16t-12.72 4.32h-7.59zm0-26.69h4.25q7.07 0 7.07-6.15t-7.07-6.15h-4.25zM516.53 136.38v38.47h15.38v10.86h-28.2v-49.33zM565.93 147.24h-15.24v8.24h14.39v10.86h-14.39v8.51h15.24v10.86h-28.07v-49.33h28.07z"
        transform="translate(-17.24 -4.38)"
      />
    </svg>
  );
}

export default HorizontalLogo;
