import React, { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import { useRollbar } from "@rollbar/react";
import IsBotContext from "lib/context/IsBotContext";

const Report = ({ level, message, variables }) => {
  const { asPath } = useRouter();
  const { userAgent, isBot } = useContext(IsBotContext);
  const rollbar = useRollbar();
  useEffect(() => {
    if (isBot) {
      const allVariables = { ...variables, asPath, userAgent };
      rollbar[level](message, allVariables);
      console[level](message, allVariables);
    }
  }, []);

  return null;
};

export default Report;
