import React from "react";
import NewHome from "components/NewHome";
import "@propertysimple/styles/styles.css";

export async function getServerSideProps(context) {
  //rollbar.error("error in extra dict", {message: 'ohoh', "error": new Error("error in extra dict")});
  if (context.query["raise-in-pages-router-page"]) {
    throw new Error("Test uncaught Error() in the pages router");
  }
  return {
    props: {},
  };
}

export default function HomePage() {
  return <NewHome />;
}
