import React from "react";
import { breakpointInPX } from "../constants";
import styled from "styled-components";
import Image from "next/legacy/image";

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  padding: 1rem 2rem;
  border-radius: 0.375rem;
  max-width: 1100px;
  gap: 1rem;
  flex-direction: ${({ imagePosition }) =>
    imagePosition === "right" ? "row" : "row-reverse"};

  @media (max-width: ${breakpointInPX}px) {
    flex-direction: column;
  }
`;

const StyledImage = styled(Image)`
  width: 50%;
  @media (max-width: ${breakpointInPX}px}) {
    width: 100%;
  }
`;

export default function CardWithImage({
  background,
  children,
  imagePosition,
  image,
}) {
  return (
    <Wrapper imagePosition={imagePosition} style={{ background }}>
      <div>{children}</div>
      <StyledImage src={image} />
    </Wrapper>
  );
}
