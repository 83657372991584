import React from "react";
import styled from "styled-components";
import { Container } from "@propertysimple/components";

export const Subtitle = styled.h3`
  letter-spacing: 0.0625rem;
  font-size: 0.875rem;
  font-weight: bold;
  color: #bfbfbf;
  margin: 2rem 0 1rem 0;
`;

export const Title = styled.h2`
  color: #424143;
  font-weight: bold;
  font-size: 1.7rem;
  line-height: 1.25;
  margin: 1rem 0;

  @media (min-width: 36rem) {
    ${({ useBigTitle }) =>
      useBigTitle ? "font-size: 3.5rem;" : "font-size: 2.5rem;"}
  }
`;

const CTA = styled.div`
  height: 36px;
  font-size: 1.5rem;
  text-align: center;
  color: #424143;
  margin: 1rem 0 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Section({
  background,
  children,
  cta,
  subtitle,
  subtitleAsH1,
  subtitleStyle,
  useBigTitle,
  title,
  titleMaxWidth,
  style = {},
}) {
  const titleSectionStyle = titleMaxWidth
    ? { margin: "auto", maxWidth: titleMaxWidth }
    : {};

  return (
    <div style={{ ...style, background }}>
      <Container>
        <div style={{ ...{ textAlign: "center", ...titleSectionStyle } }}>
          {subtitleAsH1 ? (
            <Subtitle as="h1" style={subtitleStyle}>
              {subtitle}
            </Subtitle>
          ) : (
            <Subtitle style={subtitleStyle}>{subtitle}</Subtitle>
          )}
          <Title useBigTitle={useBigTitle}>{title}</Title>
          {cta && <CTA>{cta}</CTA>}
        </div>
        {children}
      </Container>
    </div>
  );
}

export default Section;
