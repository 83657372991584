export const DEFAULT_MAP_ZOOM_LEVEL = 10;
export const MOBILE_BREAK = 850;

export const SORT_METHOD_CHEAP = 1;
export const SORT_METHOD_EXPENSIVE = 2;
export const SORT_METHOD_OLD = 3;
export const SORT_METHOD_NEW = 4;
export const SORT_METHOD_DEFAULT = SORT_METHOD_NEW;

// used as keys for the useVaule hook
export const MORE_FILTERS_OPEN = "map.moreFiltersOpen";

const PRIMARY_PARAM_DEFAULTS = {
  lowPrice: 0,
  highPrice: undefined,
  bathrooms: 0,
  bedrooms: 0,
};

export const SECONDARY_PARAM_DEFAULTS = {
  minLotSize: 0,
  maxLotSize: undefined,
  minLivingArea: 0,
  maxLivingArea: undefined,
  //has
  hasVaultedCeiling: false,
  hasFireplace: false,
  hasElevator: false,
  hasPool: false,
  hasHotTubSpa: false,
  hasSecuritySystem: false,
  hasGatedEntry: false,
  hasDisabledAccess: false,
  //propertyTypes
  propertyTypesHouses: false,
  propertyTypesApartments: false,
  propertyTypesTownhomes: false,
  propertyTypesManufacturedMobile: false,
  propertyTypesLotsLand: false,
  propertyTypesOther: false,
};

const META_PARAM_DEFAULTS = {
  bbox: undefined,
  center: undefined,
  from: 0, //page offset
  hideAddPropertiesHelp: false,
  listingCategory: "Purchase",
  placeType: "",
  queryText: undefined,
  shapeId: undefined,
  size: 50, //page size
  sort: SORT_METHOD_DEFAULT,
  source: undefined,
  zipcode: undefined,
  zoom: undefined,
};

//Default values for the query parameters
export const DEFAULT_PARAMS = Object.freeze(
  Object.assign(
    {},
    PRIMARY_PARAM_DEFAULTS,
    SECONDARY_PARAM_DEFAULTS,
    META_PARAM_DEFAULTS,
  ),
);

// ListHub property subtypes - values for reference
export const PROPERTY_SUBTYPES = [
  "Apartment",
  "Boatslip",
  "Cabin",
  "Condominium",
  "Deeded Parking",
  "Duplex",
  "Farm",
  "Manufactured Home",
  "Mobile Home",
  "Own Your Own",
  "Quadruplex",
  "Single Family Attached",
  "Single Family Detached",
  "Stock Cooperative",
  "Timeshare",
  "Townhouse",
  "Triplex",
];

// ListHub property types - vaules for reference
export const PROPERTY_TYPES = [
  "Commercial",
  "Common Interest",
  "Farm And Agriculture",
  "Lots And Land",
  "MultiFamily",
  "Rental",
  "Residential",
];

/**
 * In the UI property_type && property_sub_type fields
 * are mixed up arbitrarily
 * So we need this map to connect the checkboxes to values.
 */
export const PROPERTY_TYPES_MAP = {
  houses: {
    subtypes: ["Single Family Attached", "Single Family Detached", "Cabin"],
    types: [],
  },
  apartments: {
    subtypes: ["Apartment", "Condominium"],
    types: ["MultiFamily"],
  },
  townhomes: {
    subtypes: ["Townhouse", "Duplex", "Quadruplex", "Triplex"],
    types: ["MultiFamily"],
  },
  manufacturedMobile: {
    subtypes: ["Manufactured Home", "Mobile Home"],
    types: [],
  },
  lotsLand: {
    subtypes: [],
    types: ["Lots And Land"],
  },
  other: {
    subtypes: [
      "Boatslip",
      "Timeshare",
      "Farm",
      "Other",
      "Stock Cooperative",
      "Deeded Parking",
      "Own Your Own",
    ],
    types: ["Commercial", "Common Interest"],
  },
};

export const PRIMARY_PARAM_NAMES = Object.keys(PRIMARY_PARAM_DEFAULTS);

export const SECONDARY_PARAM_NAMES = Object.keys(SECONDARY_PARAM_DEFAULTS);
