 
import { useEffect } from "react";
import { INTERCOM_APP_ID } from "lib/constants";

function loadIntercom() {
  // From https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
  // prettier-ignore
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + INTERCOM_APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

  window.Intercom?.("boot", {
    app_id: INTERCOM_APP_ID,
  });
}

function Intercom() {
  useEffect(() => {
    loadIntercom();
  }, []);

  return null;
}

export default Intercom;
