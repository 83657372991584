import React, { useEffect } from "react";
import SEO from "components/SEO";
import styled from "styled-components";
import { baseUrl } from "lib/urls";
import {
  PsMarketingFeatures,
  PortalFooterBlack,
  Reviews,
  Testimonials,
  VipTestimonials,
  Container,
} from "@propertysimple/components";
import CTA from "./CTA";
import seoImage from "./seoImage.jpg";
import Head from "next/head";
import Intercom from "components/Intercom";
import useWindowSize from "lib/hooks/useWindowSize";
import { showIntercom } from "lib/showIntercom";
import Header from "./Header";
import PopularMarketingOptions from "./PopularMarketingOptions";
import { breakpointInPX } from "./constants";

const Wrapper = styled.div`
  font-size: 1rem;

  @media (min-width: 600px) {
    font-size: 0.8rem;
  }
`;

const Suptitle = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--clr-default-grey);
  margin: 0 0 0.5rem;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin: 0 0 2.5rem;
  text-align: center;
`;

export default function NewHome() {
  const { width } = useWindowSize();
  const enableFomo = breakpointInPX <= width;

  useEffect(() => {
    if (!enableFomo) {
      console.log("Fomo Events are %cdisabled", "color: red");
      return;
    }

    console.log(
      "Fomo Events will be displayed in %c3 seconds...",
      "color: #f47e17",
    );
    const timeout = setTimeout(function () {
      if (window.fomo && enableFomo) {
        console.log("Fomo Events are %cenabled", "color: green");
        window.fomo.trigger("start");
      } else {
        console.log("Fomo Events are %cdisabled", "color: red");
      }
    }, 3000);
    return () => {
      //remove fomo, so it does not show on other pages
      if (timeout) clearTimeout(timeout);
      if (window.fomo) {
        window.fomo.trigger("stop");
      }
    };
  }, [enableFomo]);

  return (
    <Wrapper>
      <Head>
        <script
          type="text/javascript"
          src="https://load.fomo.com/api/v1/JdvI_xRCLWj_TcKkLgwZug/load.js"
          async
        ></script>
      </Head>
      <SEO
        description={
          "Experience the power of ChatGPT by OpenAI in revolutionizing your property journey. " +
          "Elevate your success with PropertySimple's innovative " +
          "AI solutions for the modern real estate landscape!"
        }
        image={seoImage?.src}
        title="AI-Driven Real Estate Marketing and Follow-up"
        url={baseUrl}
      />
      <Header />
      <PopularMarketingOptions />
      <VipTestimonials />
      <CTA
        title="Find out more about the only solution developed especially for real estate agents to handle social media marketing and follow up with AI in an easy-to-use platform!"
        subtitle="Questions?"
        buttonText="Talk To A Team Member"
        onClick={showIntercom}
        style={{ marginBottom: "3rem" }}
      />
      <Container>
        <Suptitle>BUILT FOR RESULTS</Suptitle>
        <Title>Our Most Popular Features</Title>
        <PsMarketingFeatures environment={process.env.APP_ENVIRONMENT} />
      </Container>
      <Testimonials />
      <Reviews hideCTA />
      <CTA
        title="Don't hold back on social media any longer!"
        buttonText="Try for Free"
        href={`${baseUrl}/automation`}
      />
      <PortalFooterBlack environment={process.env.APP_ENVIRONMENT} />
      <Intercom />
    </Wrapper>
  );
}
