import React from "react";
import HorizontalLogo from "components/HorizontalLogo";
import Input from "components/NewHome/Search/Input";
import styled from "styled-components";
import Link from "next/link";
import { showIntercom } from "lib/showIntercom";
import AnimateHeight from "react-animate-height";
import useComponentVisible from "lib/hooks/useComponentVisible";
import BarsSolid from "components/Navbar/BarsSolid";
import useWidth from "lib/hooks/useWidth";
import { NAVBAR_BREAKPOINT_IN_PX } from "../constants";

const inputHeight = "2.5rem";

const StyledInput = styled(Input)`
  color: #000000;

  .property-search-box {
    height: ${inputHeight} !important;

    div {
      height: ${inputHeight} !important;

      input {
        height: ${inputHeight} !important;
      }
    }

    max-width: 20rem;
    width: 20rem;
  }

  .property-search-box-suggestions {
    color: #000000;
    top: 35px;

    div {
      div {
        height: ${inputHeight} !important;
      }
    }
  }

  .search-icon,
  .spinner-icon,
  .fa-search {
    color: #999999;
  }
`;

export default function Navbar() {
  const width = useWidth();
  const isMobile = width < NAVBAR_BREAKPOINT_IN_PX;
  const {
    isComponentVisible: displayMenu,
    setIsComponentVisible: setDisplayMenu,
    ref,
  } = useComponentVisible(false);

  const menu = (
    <>
      <a
        className="link no-underline mr-4 mb-4"
        href={`${process.env.agentsUrl}/sign-in`}
      >
        Login
      </a>
      <a
        href={`${process.env.baseUrl}/plans-and-pricing`}
        className="button button-outlined-blue mr-4 font-bold mb-4"
      >
        Pricing
      </a>
      <button
        onClick={showIntercom}
        className="button button-outlined-blue font-bold mb-4"
      >
        Sales & Support
      </button>
    </>
  );

  const search = (
    <div className="relative">
      <StyledInput placeholder="Search for your next home" />
    </div>
  );

  return (
    <div
      className="mb-2 px-8 py-3"
      style={{ background: isMobile && "#22252a" }}
      ref={ref}
    >
      <div
        style={{
          alignItems: "center",
          color: "white",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
        <div className="mr-4" style={{ display: "flex" }}>
          <Link href="/">
            <HorizontalLogo height="2.5rem" className="mr-4" />
          </Link>
          {!isMobile && search}
        </div>
        {isMobile && <BarsSolid onClick={() => setDisplayMenu(!displayMenu)} />}
        {!isMobile && <div className="center-vertically mt-4">{menu}</div>}
      </div>
      {isMobile && (
        <AnimateHeight duration={500} height={displayMenu ? "auto" : "0"}>
          <div
            className="mt-4"
            style={{
              alignItems: "baseline",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {search}
            <br />
            {menu}
          </div>
        </AnimateHeight>
      )}
    </div>
  );
}
