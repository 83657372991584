import { gql } from "@apollo/client";

export const listingUrlListingFragment = gql`
  fragment listingUrlListingFragment on Listing {
    id
    address_city
    address_full_street_address
    address_state_or_province
    category: listing_category
    deleted
    listingCategory: listing_category
  }
`;

export const sanitize = (string, defaultValue) => {
  const cleaned = string
    .replace(/[ ,_]/g, "-")
    .replace(/[^0-9a-zA-Z-]/g, "")
    .trim();
  return cleaned || defaultValue;
};

function buildUrl(listing) {
  if (typeof listing !== "object") {
    console.error("Listing is not an object");
    return false;
  }
  if (!listing) {
    console.error("Listing is not defined");
    return false;
  }

  const {
    address_state_or_province: state,
    address_full_street_address: address,
    address_city: city,
    category: listingCategory,
  } = listing || {};

  const category = listingCategory === "Purchase" ? "for-sale" : "for-rent";
  return `/search/${sanitize(state, "state")}/${sanitize(city, "city")}/${sanitize(category)}/${sanitize(address)}/${
    listing.id
  }`;
}

export const agentsUrl = process.env.agentsUrl;
export const baseUrl = process.env.baseUrl;

export function listingUrl(listing, crmUserId) {
  const path = buildUrl(listing);
  if (!path) {
    return false;
  }

  let url = `${baseUrl}${path}`;
  if (crmUserId) {
    url += `?agent_id=${crmUserId}`;
  }
  return url.toLowerCase();
}

export function getPremiumListingUrl(listing, crmUserId, config) {
  const address = sanitize(listing.address_full_street_address, "address");
  const pathname = config.pathname || "listing-website";

  if (config.host && config.pathname) {
    return `${config.host}/${pathname}/${address}/${listing.id}?crm_user_id=${crmUserId}`;
  }

  // keeping baseUrl to prevent breaking previous implementations
  return `${baseUrl}/${pathname}/${address}/${listing.id}?crm_user_id=${crmUserId}`;
}

export const agentUrl = (crmUser) => {
  if (!crmUser) {
    console.error("agentUrl: agent is not present");
    return false;
  }

  if (crmUser && !crmUser.user) {
    console.error("agent: agent doesn't have a user");
    return false;
  }

  return userProfileUrl(crmUser && crmUser.user);
};

export function userProfileUrl(user, options) {
  const { videoLanding, fullUrl } = options || {};
  if (!user) {
    console.error("userProfileUrl: user is not present");
    return false;
  }

  if (typeof user !== "object") {
    console.error("userProfileUrl: user is not an object");
    return false;
  }

  if (!user.user_name) {
    console.error("userProfileUrl: user does not have a user_name");
    return false;
  }

  let url = `/${user.user_name}`;

  if (fullUrl) {
    url = baseUrl + url;
  }

  if (videoLanding) {
    url += "?video_landing=true";
  }

  return url.toLowerCase();
}

export const articleHref = "/[username]/articles/[articleSlug]";
export const collectionHref = "/[username]/collections/[collectionSlug]";
// Don't use this Href because it is error prone, use listingHrefFor instead.
const listingHref =
  "/search/[state]/[city]/[category]/[address_slug]/[listing_id]";
// Client side transitions doesn't work if query parameters are not specified in
// the href's query property: https://github.com/zeit/next.js/issues/10494
export const listingHrefFor = (crmUserID) => ({
  pathname: listingHref,
  query: { agent_id: crmUserID },
});
export const profileHref = "/[username]";

export const getSearchUrl = (router, type) => {
  let searchParams;
  if (router.pathname === "/search") {
    searchParams = new URLSearchParams(router.query);
  } else {
    searchParams = new URLSearchParams(getPrevSearchParams());
  }
  searchParams.set("category", type);
  return `/search?${searchParams.toString()}`;
};

export function getPrevSearchParams() {
  try {
    return JSON.parse(window.localStorage.getItem("prevSearchParams") || "{}");
  } catch (error) {
    return {};
  }
}
