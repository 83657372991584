import React from "react";
import styled from "styled-components";
import Section from "../Section";
import CardWithImage from "./CardWithImage";
import { getEnvParam, Badge, MarketingPlan } from "@propertysimple/components";
import automationImage from "./automation-image.png";
import zipcodesImage from "./zip-codes.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const StyledBadge = styled(Badge)`
  position: relative;
  top: -0.125rem;
  color: white;
  background-image: linear-gradient(
    238deg,
    var(--clr-ps-purple) 20%,
    var(--clr-ps-blue) 95%
  );
  margin-right: 0.75rem;
`;

const Suptitle = styled.h3`
  margin: 1rem 0 0.75rem;
  font-size: 1.5rem;
`;

const Title = styled.h2`
  margin: 0 0 0.75rem;
  font-size: 2rem;
`;

const Description = styled.p`
  margin: 0 0 1.5rem;
  font-size: 1.5rem;
`;

const CtaButton = styled.button`
  height: 60px;
  font-size: 1.25rem;
  padding: 2px 2rem 0;
`;

export default function PopularMarketingOptions() {
  return (
    <Section
      background="white"
      titleMaxWidth="45rem"
      title="🤩 Top Selling Plans"
      subtitleAsH1
      subtitleStyle={{
        fontWeight: "normal",
        color: "#000",
        fontSize: "1.5rem",
      }}
      style={{
        marginBottom: "2rem",
      }}
    >
      <div
        style={{
          justifyItems: "center",
          display: "grid",
          gap: "1rem",
          marginBottom: "1.5rem",
        }}
      >
        <a
          href={`${process.env.agentsUrl}/automation`}
          className="no-underline text-black"
        >
          <CardWithImage image={automationImage} background="#ffe0e7">
            <Suptitle style={{ color: "#ff3867" }}>Autopilot</Suptitle>
            <Title>Market Yourself & Your Listings</Title>
            <Description>
              Automate your social media & listing ads with our{" "}
              {MarketingPlan.planName} Plan. This plan is great for agents with
              listings who want to keep up consistent marketing without much
              effort.
            </Description>
            <CtaButton className="button button-gradient purple-pink-gradient">
              Autopilot Plan Details
            </CtaButton>
          </CardWithImage>
        </a>
      </div>
      <div style={{ justifyItems: "center", display: "grid", gap: "1rem" }}>
        <a
          href={
            getEnvParam(process.env.APP_ENVIRONMENT, "base_url") +
            "/special-offers/reserve-your-zipcode"
          }
          className="no-underline text-black"
        >
          <CardWithImage
            image={zipcodesImage}
            background="var(--clr-light-purple-2)"
            imagePosition="right"
          >
            <Suptitle style={{ color: "var(--clr-ps-purple)" }}>
              <StyledBadge>
                <FontAwesomeIcon icon={faStar} /> Most Popular Plan
              </StyledBadge>{" "}
              ZIP Codes
            </Suptitle>
            <Title>Market Local Listings</Title>
            <Description>
              Exclusively market an entire ZIP Code! This plan is perfect for
              agents who want to increase visibilty in their area. Flexible
              plans to fit any budget, no annual contract.
            </Description>
            <CtaButton className="button button-gradient purple-blue-gradient">
              Claim My Zip!
            </CtaButton>
          </CardWithImage>
        </a>
      </div>
    </Section>
  );
}
