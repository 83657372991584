import React from "react";

export default function Content() {
  return (
    <div
      style={{
        display: "grid",
        alignItems: "baseline",
        justifyItems: "baseline",
      }}
    >
      <img
        src="/images/4.5-stars.png"
        className="mb-2"
        alt=""
        style={{ height: "1rem" }}
      />{" "}
      <strong>4.5 stars!</strong>
      <p className="mt-1">
        Over <strong>450 positive</strong> reviews by agents & experts
      </p>
      <h1 className="text-inherit text-3xl mb-0">
        Automated Ads, Marketing, and Follow Up with AI for Real Estate Agents
      </h1>
      <p className="text-inherit my-4">
        AI Marketing on TikTok, Instagram, Facebook, LinkedIn & Twitter. Follow
        up with leads automatically with ChatGPT4 via email & text plus so much
        more...
      </p>
      <a
        href={`${process.env.agentsUrl}/automation`}
        className="button button-outlined-purple font-bold mb-2"
      >
        Get Started
      </a>
    </div>
  );
}
